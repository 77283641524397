/*
 * @Author: your name
 * @Date: 2020-12-01 13:55:54
 * @LastEditTime: 2024-07-08 17:14:48
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \slide-tct\src\router\index.js
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const web = (name) => () => import(`@/views/${name}.vue`);
const mobile = (name) => () => import(`@/mobile/pages/${name}.vue`);

// const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && false;

const isMobile = _isMobile()

function _isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

const both = isMobile ? mobile : web


export default new Router({
  routes: [{
      path: "/login",
      name: "login",
      component: both("Login"),
      // component: () => import("../views/Login.vue"),
      meta: {
        title: '登录页'
      }
    },
    {
      path: "/",
      name: "home",
      // component: () => import("../views/Home.vue"),
      component: both("home/Home"),
      meta: {
        title: '首页'
      },
    },
    {
      path: "/about",
      name: "about",
      // component: () => import("../views/Home.vue"),
      component: both("about/About"),
      meta: {
        title: '关于品信'
      },
    },
    {
      path: "/product",
      name: "product",
      component: both("products/Product"),
      meta: {
        title: '产品及解决方案'
      },
      // children:[
      //   {
      //     path: "tbs",
      //     // name: "tbs",
      //     component: both("products/details/Tbs"),
      //     meta: { 
      //       title: '宫颈癌筛查解决方案'
      //     },
      //   }
      // ]
    },
    {
      path: "/product",
      component: both("products/SubView"),
      children: [{
          path: "tbs",
          component: both("products/details/Tbs"),
          meta: {
            title: '宫颈癌筛查解决方案'
          },
        },
        {
          path: "dna",
          component: both("products/details/Dna"),
          meta: {
            title: 'DNA倍体癌症早筛解决方案'
          },
        },
        {
          path: "telepathology",
          component: both("products/details/Telepathology"),
          meta: {
            title: '远程病理诊断解决方案'
          },
        },
        {
          path: "blood",
          component: both("products/details/Blood"),
          meta: {
            title: '血液细胞形态诊断解决方案'
          },
        },

        {
          path: "dnaReagent",
          component: both("products/details/DnaReagent"),
          meta: {
            title: 'DNA定量分析诊断试剂'
          },
        },

        {
          path: "tctReagent",
          component: both("products/details/TctReagent"),
          meta: {
            title: '液基细胞学TCT诊断试剂'
          },
        },


        {
          path: "gemini",
          component: both("products/details/Gemini"),
          meta: {
            title: '吉米娜系列'
          },
        },

        {
          path: "pelleter",
          component: both("products/details/Pelleter"),
          meta: {
            title: '液基制片染色一体机'
          },
        },

        {
          path: "film",
          component: both("products/details/Film"),
          meta: {
            title: '液基膜式制片机'
          },
        },

        {
          path: "special",
          component: both("products/details/Special"),
          meta: {
            title: '全自动特殊染色机'
          },
        },

        {
          path: "dnaProd",
          component: both("products/details/DnaProd"),
          meta: {
            title: 'DNA定量分析系统'
          },
        },

        {
          path: "marrow",
          component: both("products/details/Marrow"),
          meta: {
            title: '骨髓/血液细胞形态学AI扫描分析系统'
          },
        },

        {
          path: "bloodPushing",
          component: both("products/details/BloodPushing"),
          meta: {
            title: '全自动推片染色机'
          },
        },

        {
          path: "tctProd",
          component: both("products/details/TctProd"),
          meta: {
            title: '细胞学TCT人工智能辅助诊断'
          },
        },
        {
          path: "patholog",
          component: both("products/details/Patholog"),
          meta: {
            title: '远程病理会诊系统'
          },
        }

      ]
    },
    {
      path: "/medium",
      name: "medium",
      component: both("medium/Medium"),
      meta: {
        title: '媒体中心'
      },
    },
    {
      path: "/medium",
      name: "medium",
      component: both("medium/SubView"),
      meta: {
        title: '媒体中心'
      },
      children: [
        {
          path: "/mediumDetail/:id",
          component: both("medium/MediumDetail"),
          meta: {
            title: '新闻详情页'
          }
        },
      ]
    },
    {
      path: "/science",
      name: "science",
      component: both("science/Science"),  
      meta: {
        title: '学术园地'
      },
    },
    {
      path: "/science",
      name: "science",
      component: both("science/SubView"),
      meta: {
        title: '媒体中心'
      },
      children: [
        {
          path: "/decipherDetail/:id", 
          component: both("science/DecipherDetail"),
          meta: {
            title: '文献解读详情页'
          }
        },
        {
          path: "/doctorDetail/:id", 
          component: both("science/DoctorDetail"), 
          meta: {
            title: '吉博士视频详情页'
          }
        },
      ]
    },
    {
      path: "/contact",
      name: "contact",
      component: both("contact/Contact"),
      meta: {
        title: '人才招募'
      },
    },
    {
      path: "/decennial",
      name: "decennial",
      component: both("decennial/Decennial"),
      meta: {
        title: '品信十周年'
      },
    },

  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 如果之前有保存滚动位置，则恢复到之前保存的位置
      return savedPosition;
    } else {
      // 否则，默认滚动到页面顶部
      return {
        x: 0,
        y: 0
      };
    }
  }
})