<!--
 * @Author: your name
 * @Date: 2020-12-01 11:10:17
 * @LastEditTime: 2024-11-27 14:50:00
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \slide-tct\src\App.vue
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import ZoomRatio from './utils/zoom'
// import DevicePixelRatio from './utils/devicePixelRatio'
export default {
  name: "App",
  components: {},
  created() {
    // new ZoomRatio().init()
    // new DevicePixelRatio().init()
  },
  mounted() {
    // 通过监听hashchange事件 判断当前页面的路由与实际的路由是否一致
    window.addEventListener(
      "hashchange",
      () => {
        let currPath = window.location.hash.slice(1);
        if (this.$route.path !== currPath) {
          // 不一致就跳转url的路由
          this.$router.push(currPath);
        }
      },
      false
    );
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none !important;
  // -webkit-user-select: none;
}
#app {
  // overflow-y: auto;
}
</style>
