/*
 * @Author: your name
 * @Date: 2022-04-13 09:05:51
 * @LastEditTime: 2024-03-06 14:07:52
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \cloud-diagnos\src\utils\rem.js
 */
// window.onresize = function () {
//   fnResize()
// }

// function fnResize() {
//   var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
//   if (deviceWidth >= 750) {
//     deviceWidth = 750;
//   }
//   if (deviceWidth <= 320) {
//     deviceWidth = 320;
//   }
//   document.documentElement.style.fontSize = (deviceWidth / 7.5) + 'px';
// }


// rem等比适配配置文件
// 基准大小
const baseSize = 16
// 设置 rem 函数
function setRem() {
  // 当前页面屏幕分辨率相对于 1920宽的缩放比例，可根据自己需要修改
  const deviceWidth = document.documentElement.clientWidth || window.innerWidth;
  const scale = document.documentElement.clientWidth / 1920

  const isMobile = _isMobile()

  if(isMobile) {
    // document.documentElement.style.fontSize = (deviceWidth / 23.38) + 'px';
  } else {
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
  }

}

function _isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}